import { createContext } from 'react'

const JournalsContext = createContext({
  loading: false,
  loaded: false,
  data: [],
  error: null,
})

export default JournalsContext
