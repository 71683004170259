import { createContext } from 'react'

const BuildsContext = createContext({
  loading: false,
  loaded: false,
  data_builds: [],
  error: null,
  refreshBuilds: () => {},
})

export default BuildsContext
