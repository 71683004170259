import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material'
import PropTypes from 'prop-types'

const EditDialogUsers = ({ show, onHide, onSave, data, setData }) => {
  const [formData, setFormData] = useState(data)

  useEffect(() => {
    setFormData(data)
  }, [data])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleSaveClick = () => {
    setData(formData)
    onSave(formData)
  }

  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
    { value: 'integration', label: 'Integration' },
  ]

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Изменение пользователя</DialogTitle>
      <DialogContent>
        <TextField
          label="Почта"
          type="email"
          InputLabelProps={{ shrink: true }}
          value={formData.email || ''}
          onChange={handleInputChange}
          name="email"
          fullWidth
          margin="dense"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="role-select-label">Роль</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            label="Роль"
            value={formData.role || ''}
            onChange={handleInputChange}
            name="role"
            sx={{
              borderRadius: '5px',
            }}
          >
            {roles.map((row, index) => (
              <MenuItem key={index} value={row.value}>
                {row.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSaveClick} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditDialogUsers.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    role: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  setData: PropTypes.func.isRequired,
}

export default EditDialogUsers
