import React, { useState } from 'react'
import {
  Paper,
  ToggleButton,
  Typography,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: '0.5rem',
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
  [`& .firstButton`]: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}))

function ToggleGroup({
  buttons,
  onChanged,
  name,
  title,
  color,
  sx,
  startValue,
  disabledButtons = [],
}) {
  const checkSelected = (v) => {
    for (const r of buttons) {
      if (r.key === v) return v
    }
    return buttons.length > 0 ? buttons[0].key : ''
  }

  const [selected, setSelected] = useState(() => {
    return startValue !== null && startValue !== undefined
      ? startValue
      : checkSelected('')
  })

  const handleSelect = (event, v) => {
    if (v !== null) {
      setSelected(v)
      onChanged(name, v)
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        border: `none`,
        borderRadius: '0.8rem',
        flexWrap: 'wrap',
        ...sx,
      }}
    >
      {title && (
        <Typography
          variant="subtitle2"
          alignSelf="center"
          color="#bdbdbd"
          textTransform="uppercase"
          margin="0 0.5rem 0 0.5rem"
        >
          {title}
        </Typography>
      )}
      <StyledToggleButtonGroup
        color={color || 'secondary'}
        value={selected}
        exclusive
        onChange={handleSelect}
      >
        {buttons.map((row, index) => (
          <ToggleButton
            value={row.key}
            key={index}
            disabled={disabledButtons.includes(row.key)}
          >
            {row.name || row.icon}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Paper>
  )
}

ToggleGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string,
      icon: PropTypes.element,
    })
  ).isRequired,
  onChanged: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  startValue: PropTypes.string,
  disabledButtons: PropTypes.arrayOf(PropTypes.string),
}

export default ToggleGroup
