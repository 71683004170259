import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import SnackbarNotification from '../../shared/ui/Components/SnackbarNotification'

const OptionsDialog = ({ show, onHide, onSave, data = {} }) => {
  const [options, setOptions] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarNotification, setSnackbarNotification] = useState({
    message: '',
    severity: 'success',
  })

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  useEffect(() => {
    if (data?.options) {
      setOptions(JSON.stringify(data.options, null, 2))
    }
  }, [data])

  const handleSave = () => {
    try {
      const parsedOptions = JSON.parse(options)
      if (onSave) {
        onSave({
          ...data,
          options: parsedOptions,
        })
      }
    } catch (error) {
      setSnackbarNotification({
        message: `Ошибка: ${error.message}`, // Сообщение об ошибке
        severity: 'error',
      })
      setSnackbarOpen(true)
    }
  }

  return (
    <>
      <SnackbarNotification
        notification={snackbarNotification}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <Dialog open={show} onClose={onHide}>
        <DialogTitle>Настройки</DialogTitle>
        <DialogContent>
          <TextField
            label="Options (JSON)"
            value={options}
            onChange={(e) => setOptions(e.target.value)}
            placeholder='Например: {"key": "value", "anotherKey": 123}'
            multiline
            sx={{ minWidth: 300 }}
            rows={10}
            margin="dense"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onHide} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

OptionsDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    options: PropTypes.object,
  }),
}

export default OptionsDialog
