import React, { useEffect } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import { Box, Stack } from '@mui/material'
import SystemMenu from '../../shared/ui/SystemMenu'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import JournalsProvider from '../../app/Sonars/JournalsProvider'
import JournalsTable from '../../widgets/DataView/JournalsTable'

function Journals() {
  const { isAuthenticated, getToken, login } = useAuth()

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  return (
    <SystemMenu>
      <h1>Журналы эхолота</h1>
      <Stack direction={'column'}>
        <JournalsProvider
          query={{
            offset: 0,
            count: 10,
          }}
        >
          <ContentPaper>
            <JournalsTable />
          </ContentPaper>
        </JournalsProvider>
        <Box height={20} />
      </Stack>
    </SystemMenu>
  )
}

export default Journals
