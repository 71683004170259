import isJsonResponse from './isJsonResponse'
import isBlobResponse, { fileNameFromHeaders } from './isBlobResponse'

function request(url, method, data, headers) {
  const common = {
    method,
    headers: {
      Accept: 'application/json',
      ...headers,
    },
  }

  const body =
    data !== null
      ? {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      : { headers: {} }

  return new Promise((resolve, reject) => {
    fetch('/api' + url, {
      ...common,
      ...body,
      headers: {
        ...common.headers,
        ...body.headers,
      },
    }).then((response) => {
      if (response.ok) {
        if (isJsonResponse(response)) {
          resolve(response.json())
        } else if (isBlobResponse(response)) {
          response.blob().then((blob) =>
            resolve({
              blob: blob,
              filename: fileNameFromHeaders(response),
            })
          )
        } else {
          resolve(response.text())
        }
      } else {
        if (response && isJsonResponse(response)) {
          response.json().then((r) => reject(r))
        } else {
          reject(new Error(response.code + ' ' + response.statusText))
        }
      }
    })
  })
}

const fetchUpload = (url, options, onProgress) => {
  const request = new XMLHttpRequest()
  return {
    xhr: request,
    promise: new Promise((resolve, reject) => {
      request.open(options.method, url, true)
      request.onloadend = (e) => {
        onProgress(100)
        if (request.status !== 200) {
          if (request.status === 0) reject(new Error('Обрыв связи'))
          let errorMessage = request.status + ' ' + request.statusText
          try {
            errorMessage = JSON.parse(request.response).message
          } catch (e) {}
          reject(new Error(errorMessage))
        }
        try {
          const result = JSON.parse(request.response)
          resolve(result)
        } catch (e) {
          reject(e)
        }
      }
      request.upload.onerror = (e) => reject(e)
      request.upload.onprogress = (e) => {
        onProgress((e.loaded / e.total) * 100)
      }
      request.send(options.body)
    }),
  }
}

const api = {
  get: (url, headers = {}) => request(url, 'GET', null, headers),
  post: (url, data = null, headers = {}) => request(url, 'POST', data, headers),
  put: (url, data = null, headers = {}) => request(url, 'PUT', data, headers),
  delete: (url, headers = {}) => request(url, 'DELETE', null, headers),
  upload: (url, options, onProgress) => fetchUpload(url, options, onProgress),
  objectToQuery: (args) => new URLSearchParams(args).toString(),
}

export default api
