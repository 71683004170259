import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'

function CreateDialogUser({ show, onHide, onSave, data: initialData }) {
  const [data, setData] = useState(initialData)

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  const handleCreateApp = (e, key) => {
    const newData = { ...data, [key]: e.target.value }
    setData(newData)
  }

  const handleSaveApp = () => {
    if (onSave != null) onSave(data)
  }

  const fields = [
    {
      label: 'Почта',
      key: 'email',
      type: 'email',
    },
    {
      label: 'Пароль',
      key: 'password',
      type: 'password',
    },
    {
      label: 'Повторите пароль',
      key: 'repeat_password',
      type: 'password',
    },
  ]

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Создание нового приложения</DialogTitle>
      <DialogContent>
        {fields.map((field, index) => (
          <TextField
            key={field.key}
            margin="dense"
            label={field.label}
            type={field.type}
            fullWidth
            onChange={(e) => handleCreateApp(e, field.key)}
            value={data[field.key]}
            autoFocus={index === 0}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSaveApp} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateDialogUser.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

export default CreateDialogUser
