import React, { useContext } from 'react'
import {
  Stack,
  Select,
  MenuItem,
  FormControl,
  LinearProgress,
} from '@mui/material'
import GroupsContext from '../../app/Administration/GroupsContext'
import PropTypes from 'prop-types'

function SelectOptionsIndicators({ selectedGroup, handleGroupChange }) {
  const { dataGroups, loading } = useContext(GroupsContext)
  return (
    <>
      <Stack sx={{ mt: 2 }}>
        {loading ? (
          <LinearProgress />
        ) : (
          <FormControl
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <h3 style={{ marginRight: '1rem' }}>Группа</h3>
            <Select value={selectedGroup} onChange={handleGroupChange}>
              <MenuItem value="any">Любая</MenuItem>
              {dataGroups.map((group, index) => (
                <MenuItem key={index} value={group.id}>
                  {group.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
    </>
  )
}

SelectOptionsIndicators.propTypes = {
  selectedGroup: PropTypes.string,
  handleGroupChange: PropTypes.func,
}

export default SelectOptionsIndicators
