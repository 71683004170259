import { createContext } from 'react'

const StatsContext = createContext({
  loading: false,
  loaded: false,
  data: [],
  error: null,
})

export default StatsContext
