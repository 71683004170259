import React from 'react'
import PropTypes from 'prop-types'
import 'moment/locale/ru'
import { Chip } from '@mui/material'

const indicatorViewValues = [
  { value: 'new', label: 'Выключен', color: 'default' },
  { value: 'linear', label: 'Линейный', color: 'info' },
  { value: 'segmented', label: 'Сегментированный', color: 'info' },
  { value: 'stacked', label: 'Сложный', color: 'info' },
  { value: 'diagram', label: 'Диагрммный', color: 'info' },
]

function IndicatorValueView({ value }) {
  let info = { value: value, label: value, color: 'error' }
  for (const v of indicatorViewValues) {
    if (v.value === value) {
      info = v
      break
    }
  }
  return <Chip label={info.label} color={info.color} />
}

IndicatorValueView.propTypes = {
  value: PropTypes.string,
}

export { IndicatorValueView, indicatorViewValues }
