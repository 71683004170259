import { Paper } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

function ContentPaper({ children }) {
  return (
    <Paper elevation={0} sx={{ py: 1.5, px: 2.5, mt: 2, borderRadius: '1rem' }}>
      {children}
    </Paper>
  )
}

ContentPaper.propTypes = {
  children: PropTypes.node,
}

export default ContentPaper
