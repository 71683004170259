import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import DfContext from './DfContext'
import api from '../../shared/api'
import { useAuth } from '../OAuth/Provider'

function DfProvider({ children }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState({})

  const { getToken } = useAuth()

  const loader = () => {
    setLoading(true)
    getToken().then((accessToken) =>
      api
        .get('/v1/admins/system/df', {
          authorization: accessToken,
        })
        .then((r) => {
          setData(r)
          setLoading(false)
          setLoaded(true)
        })
        .catch((e) => {
          setError(e.message)
          setLoading(false)
        })
    )
  }

  useEffect(loader, [])

  const refresh = useCallback(loader, [])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      data,
      refresh,
    }),
    [loaded, loading, error, data]
  )

  return (
    <DfContext.Provider value={contextValue}>{children}</DfContext.Provider>
  )
}

DfProvider.propTypes = {
  children: PropTypes.node,
}

export default DfProvider
