import React from 'react'
import styles from './MainMenu.module.css'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useNavigate } from 'react-router-dom'
import { ListItem, ListItemButton, ListSubheader } from '@mui/material'
import List from '@mui/material/List'

function MainMenu({ titleEnd, items }) {
  const navigate = useNavigate()
  const list = items.map((item, index) => {
    if (!item.url) item.url = ''
    const selected =
      window.location.pathname === item.url ? styles.selected : null
    if (window.location.pathname === item.url)
      document.title = item.name + (titleEnd || '')
    if (item.divider) {
      if (item.label)
        return (
          <ListSubheader
            sx={{ mt: 4, textTransform: 'uppercase', fontSize: 12 }}
            key={index}
            component="div"
            id="nested-list-subheader"
          >
            {item.label}
          </ListSubheader>
        )
      else return <Divider key={index} />
    } else
      return (
        <ListItem
          disabled={item.disabled}
          disablePadding
          key={index}
          onClick={() => {
            if (item.disabled) return
            navigate(item.url)
          }}
        >
          <ListItemButton className={selected}>
            {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
            <ListItemText
              inset={!item.icon}
              primary={item.name}
              primaryTypographyProps={{ color: 'black' }}
            />
          </ListItemButton>
        </ListItem>
      )
  })

  return (
    <div className={styles.container}>
      <List>{list}</List>
    </div>
  )
}

MainMenu.propTypes = {
  items: PropTypes.array,
  showRightButtons: PropTypes.bool,
  titleEnd: PropTypes.string,
}

export default MainMenu
