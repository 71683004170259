import { createContext } from 'react'

const GroupsContext = createContext({
  loading: false,
  loaded: false,
  dataGroups: [],
  error: null,
})

export default GroupsContext
