import DocumentScannerRoundedIcon from '@mui/icons-material/DocumentScannerRounded'
import GradingIcon from '@mui/icons-material/Grading'
import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import AppsIcon from '@mui/icons-material/Apps'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import AdUnitsIcon from '@mui/icons-material/AdUnits'
import SummarizeIcon from '@mui/icons-material/Summarize'
import AllInboxIcon from '@mui/icons-material/AllInbox'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SettingsIcon from '@mui/icons-material/Settings'

export default [
  {
    url: '/',
    name: 'Обзор',
    icon: <DashboardIcon />,
    iconLarge: (p) => <DashboardOutlinedIcon {...p} />,
  },
  {
    url: '/stats/installations',
    name: 'Установки',
    color: '#ea3',
    disabled: false,
    icon: <AppsIcon />,
    iconLarge: (p) => <GradingIcon {...p} />,
  },
  {
    url: '/stats/data',
    name: 'Показатели',
    color: '#ea3',
    disabled: false,
    icon: <StackedBarChartIcon />,
    iconLarge: (p) => <GradingIcon {...p} />,
  },
  {
    url: '/sonars/devices',
    name: 'Эхолоты',
    color: '#e53',
    disabled: true,
    icon: <AdUnitsIcon />,
    iconLarge: (p) => <DocumentScannerOutlinedIcon {...p} />,
  },
  {
    url: '/sonars/journals',
    name: 'Журналы',
    color: '#e53',
    disabled: false,
    icon: <SummarizeIcon />,
    iconLarge: (p) => <DocumentScannerOutlinedIcon {...p} />,
  },
  { divider: true, label: 'Обновления' },
  {
    url: '/updates/apps',
    name: 'Приложения',
    color: '#e53',
    disabled: false,
    icon: <DocumentScannerRoundedIcon />,
    iconLarge: (p) => <DocumentScannerOutlinedIcon {...p} />,
  },
  {
    url: '/updates/builds',
    name: 'Сборки',
    color: '#e53',
    disabled: false,
    icon: <AllInboxIcon />,
    iconLarge: (p) => <DocumentScannerOutlinedIcon {...p} />,
  },
  { divider: true, label: 'Администрирование' },
  {
    url: '/admin/users',
    name: 'Пользователи',
    color: '#e53',
    disabled: false,
    icon: <ManageAccountsIcon />,
    iconLarge: (p) => <DocumentScannerOutlinedIcon {...p} />,
  },
  {
    url: '/admin/settings',
    name: 'Настройки',
    color: '#ea3',
    disabled: false,
    icon: <SettingsIcon />,
    iconLarge: (p) => <GradingIcon {...p} />,
  },
]
