import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import { formatDigital } from '../../shared/units'

function SingleDigitalValue({
  label,
  color,
  value,
  round,
  thousand,
  units,
  children,
}) {
  if (!round) round = true
  if (!thousand) thousand = 1024
  if (!units) units = ['б', 'кБ', 'МБ', 'ГБ']

  return (
    <Stack direction={'column'} sx={{ mx: 2 }}>
      <Typography variant={'caption'}>{label}</Typography>
      <Stack
        direction={'row'}
        sx={{
          mt: 1,
          borderRadius: 4,
          minWidth: 150,
        }}
      >
        <Typography variant={'h4'} color={color}>
          {round ? formatDigital(value, thousand, units) : value}
        </Typography>
        {children}
      </Stack>
    </Stack>
  )
}

SingleDigitalValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  round: PropTypes.bool,
  units: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
  thousand: PropTypes.number,
  children: PropTypes.node,
}

export default SingleDigitalValue
