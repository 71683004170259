import { createContext } from 'react'

const DfContext = createContext({
  loading: false,
  loaded: false,
  data: {},
  error: null,
  refresh: () => {},
})

export default DfContext
