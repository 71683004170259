const ContentToggleButtonSegmented = [
  {
    title: 'Период',
    name: 'period',
    buttons: [
      { key: 'year', name: 'Год' },
      { key: 'quarter', name: 'Квартал' },
      { key: 'month', name: 'Месяц' },
      { key: 'week', name: 'Неделя' },
    ],
    sx: { marginRight: '1rem', border: '1px solid #fcf1e0' },
    getDisabledDetailButtons: (period) => {
      switch (period) {
        case 'month':
          return ['month']
        case 'week':
          return ['week', 'month']
        default:
          return []
      }
    },
  },
  {
    title: 'Детализация',
    name: 'detail',
    buttons: [
      { key: 'month', name: 'Месяц' },
      { key: 'week', name: 'Неделя' },
    ],
    sx: { border: '1px solid #fcf1e0' },
  },
]

export default ContentToggleButtonSegmented
