import React, { useContext } from 'react'
import {
  Alert,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import StatsContext from '../../app/Stats/StatsContext'
import FormattedDate from './FormattedDate'

function InstallationsTable(props) {
  const renderTable = (rows) => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Дата установки</TableCell>
              <TableCell>Последняя отправка</TableCell>
              <TableCell>ОС</TableCell>
              <TableCell>Версия приложения</TableCell>
              <TableCell align="right">Кол-во отправок</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <FormattedDate timestamp={row.creationDate} />
                </TableCell>
                <TableCell>
                  <FormattedDate timestamp={row.uptime} />
                </TableCell>
                <TableCell>
                  <div>{row.device}</div>
                  <Box
                    sx={{ fontSize: '0.8em', mt: 0.5, color: 'text.secondary' }}
                  >
                    {row.id}
                  </Box>
                </TableCell>
                <TableCell>{row.appVersion}</TableCell>
                <TableCell align="right">{row.sendings}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const { data, loading, error } = useContext(StatsContext)
  return (
    <Box sx={{ my: 2 }}>
      {loading ? <CircularProgress /> : <Box>{renderTable(data)}</Box>}
      {error != null && (
        <Alert variant="outlined" severity="error">
          {error}
        </Alert>
      )}
    </Box>
  )
}

InstallationsTable.propTypes = {
  view: PropTypes.string, // chart, diagram, table
  type: PropTypes.string,
  children: PropTypes.node,
}

export default InstallationsTable
