import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import 'moment/locale/ru'

export default class FormattedDate extends React.Component {
  render() {
    const format = this.props.format ? this.props.format : 'D MMM Y H:mm'
    const date = new Date(this.props.timestamp * 1000)
    return Moment(date).locale('ru').format(format)
  }
}

FormattedDate.propTypes = {
  format: PropTypes.string,
  timestamp: PropTypes.any,
}
