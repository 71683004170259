import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AppsContext from '../../app/Sonars/AppsContext'
import {
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  LinearProgress,
} from '@mui/material'

function SelectBuilds({
  selectedApp,
  selectedBranch,
  handleAppChange,
  handleBranchChange,
}) {
  const { dataApps, loading } = useContext(AppsContext)

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '10px 20px',
    margin: '5px 0',
    borderRadius: '15px',
  }))

  const threadSelect = [
    {
      value: 'release',
      name: 'Release',
    },
    {
      value: 'test',
      name: 'Test',
    },
    {
      value: 'dev',
      name: 'Dev',
    },
    {
      value: 'beta',
      name: 'Beta',
    },
  ]
  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="branch-select-label">Приложение</InputLabel>
            <Select
              id="app-select"
              value={selectedApp}
              onChange={handleAppChange}
              label={'Приложение'}
            >
              {dataApps.map((row) => (
                <StyledMenuItem key={row.id} value={row.code}>
                  {row.code}{' '}
                  <span style={{ opacity: 0.5, padding: '0 0.5em' }}>
                    {row.name}
                  </span>
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="branch-select-label">Ветка</InputLabel>
            <Select
              labelId="branch-select-label"
              id="branch-select"
              value={selectedBranch}
              onChange={handleBranchChange}
              label="Ветка"
            >
              {threadSelect.map((row) => (
                <StyledMenuItem key={row.value} value={row.value}>
                  {row.name}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
    </>
  )
}

SelectBuilds.propTypes = {
  selectedBranch: PropTypes.string,
  selectedApp: PropTypes.string,
  handleAppChange: PropTypes.func,
  handleBranchChange: PropTypes.func,
}

export default SelectBuilds
