import React from 'react'
import styles from './SystemMenu.module.css'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import MainMenu from '../MainMenu'
import menu from '../../../app/menu'
import { styled } from '@mui/material/styles'

const SystemBack = styled('div', {
  name: 'MuiStat', // The component name
  slot: 'root', // The slot name
})(({ theme }) => ({
  backgroundColor: '#eee',
}))

function SystemMenu({ children }) {
  return (
    <SystemBack className={styles.container}>
      <div className={styles.menu}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={'/media/logo.png'} alt={'Практик.Mertics.Updates'} />
          </div>
        </div>
        <MainMenu
          items={menu}
          titleEnd={' — Практик'}
          showRightButtons={true}
        />
      </div>
      <div className={styles.content}>
        <Box>{children}</Box>
      </div>
    </SystemBack>
  )
}

SystemMenu.propTypes = {
  children: PropTypes.any,
}

export default SystemMenu
