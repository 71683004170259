import React from 'react'
import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material'
import { formatFileSize } from '../units'
import BuildVersion from '../../shared/ui/BuildVersion'
import PropTypes from 'prop-types'
import { DownloadBuildButton } from './DownloadButton'
import FormattedDate from './FormattedDate'

function BuildCard({ build }) {
  return (
    <Box
      sx={{
        my: 1,
        bgcolor: '#f5f5ff',
        borderRadius: 5,
        py: 2,
        px: 3,
      }}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        {build.app && (
          <Tooltip title={build.app.name}>
            <Typography variant={'h5'} sx={{ color: 'grey' }}>
              {build.app.code}
            </Typography>
          </Tooltip>
        )}
        <Typography variant={'h5'}>
          <BuildVersion fullVersion={build.full} />
        </Typography>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        alignContent={'space-between'}
        spacing={2}
        sx={{
          pt: 2,
        }}
      >
        <Box flexGrow={2}>
          <FormattedDate
            date={build.date}
            format={{
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: 'numeric',
            }}
          />
        </Box>
        <Chip label={formatFileSize(build.filesize ?? 0)} />
        <DownloadBuildButton id={build.id} />
      </Stack>
    </Box>
  )
}

BuildCard.propTypes = {
  build: PropTypes.object,
}

export default BuildCard
