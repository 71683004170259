import React, { useEffect } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import SystemMenu from '../../shared/ui/SystemMenu'
import UsersProvider from '../../app/Administration/UsersProvider'
import UsersTable from '../../widgets/DataView/UsersTable'

function Users() {
  const { isAuthenticated, getToken, login } = useAuth()

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  return (
    <SystemMenu>
      <h1>Пользователи</h1>
      <UsersProvider>
        <UsersTable />
      </UsersProvider>
    </SystemMenu>
  )
}

export default Users
