import { Buffer } from 'buffer'

function isBlobResponse(response) {
  if (!response) return false
  const type = response.headers.get('content-description')
  return type && type.includes('File Transfer')
}

function fileNameFromHeaders(response) {
  if (!response) return ''
  console.log(response)
  const disp = response.headers.get('content-disposition')
  const res = disp.match(/filename="(.*?)"/)
  const resUtf8 = disp.match(/filename[*]=(.*?)''(.*)/)
  if (!res) return ''
  if (resUtf8) {
    return Buffer.from(resUtf8[2], 'ascii').toString(resUtf8[1])
  }
  return res[1]
}

export { isBlobResponse, fileNameFromHeaders }
export default isBlobResponse
