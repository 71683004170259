import { createContext } from 'react'

const AppsContext = createContext({
  loading: false,
  loaded: false,
  dataApps: [],
  error: null,
  refreshApps: () => {},
})

export default AppsContext
