import { createContext, useContext } from 'react'

const UsersContext = createContext({
  loading: false,
  loaded: false,
  data: [],
  error: null,
  refreshUsers: () => {},
})

export const useUser = () => {
  const context = useContext(UsersContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UsersProvider')
  }
  return context
}

export default UsersContext
