import React, { useEffect } from 'react'
import SystemMenu from '../../shared/ui/SystemMenu'
import { useAuth } from '../../app/OAuth/Provider'
import { Box, Typography } from '@mui/material'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import BuildsInfoProvider from '../../app/System/BuildsInfoProvider'
import BuildsInfo from '../../widgets/System/BuildsInfo'
import DfProvider from '../../app/System/DfProvider'
import DfInfo from '../../widgets/System/DfInfo'

function Dashboard() {
  const { isAuthenticated, getToken, login } = useAuth()

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  return (
    <SystemMenu>
      <h1>Обзор</h1>
      <ContentPaper>
        <Typography variant={'h6'}>
          Ссылки на магазины для Мой Практик
        </Typography>
        <Box sx={{ mt: 2 }}>
          <div className="uwt_stores_container">
            <a
              href="https://play.google.com/store/apps/details?id=ru.praktik.sonar"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://stat.rusonar.ru/widget/google-play.svg"
                width="170"
                height="50"
                alt="GooglePlay"
              />
            </a>
            &nbsp;
            <a
              href="https://apps.rustore.ru/app/ru.praktik.sonar"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://stat.rusonar.ru/widget/rustore.svg"
                width="146"
                height="50"
                alt="RuStore"
              />
            </a>
          </div>
        </Box>
      </ContentPaper>
      <ContentPaper>
        <BuildsInfoProvider>
          <Typography variant={'h6'}>Сборки</Typography>
          <BuildsInfo />
        </BuildsInfoProvider>
      </ContentPaper>
      <ContentPaper>
        <DfProvider>
          <Typography variant={'h6'}>Состояние хранилища</Typography>
          <DfInfo />
        </DfProvider>
      </ContentPaper>
    </SystemMenu>
  )
}

export default Dashboard
