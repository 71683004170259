import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const CustomTooltip = ({ active, payload, label, type }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '1rem 1rem 0rem 1rem',
          borderRadius: '0.3rem',
        }}
      >
        <p style={{ color: 'rgba(51, 51, 51, 0.8)' }}>{`Дата: ${label}`}</p>

        {payload.map((item, index) => {
          return (
            <p key={index} style={{ color: item.color }}>
              {type !== 'linear'
                ? `Количество совпадений: ${item.value}`
                : `Значение: ${item.value}`}
            </p>
          )
        })}
      </Box>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
      payload: PropTypes.object,
    })
  ),
  label: PropTypes.string,
  type: PropTypes.string,
}

export default CustomTooltip
