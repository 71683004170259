import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, Alert } from '@mui/material'

const SnackbarNotification = ({ notification, open, onClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity={notification.severity}
        sx={{ width: '100%' }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}

SnackbarNotification.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SnackbarNotification
