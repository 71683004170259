import { createContext } from 'react'

const BuildsInfoContext = createContext({
  loading: false,
  loaded: false,
  data: {},
  error: null,
})

export default BuildsInfoContext
