import React, { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import SystemMenu from '../../shared/ui/SystemMenu'
import ToggleGroup from '../../shared/ui/Components/ToggleGroup'
import GroupsProvider from '../../app/Administration/GroupsProvider'
import GroupsContext from '../../app/Administration/GroupsContext'
import { CircularProgress, LinearProgress } from '@mui/material'
import PropTypes from 'prop-types'
import ListChartsIndicators from '../../widgets/ListCharts/ListChartsIndicators'
import TypesProvider from '../../app/Administration/TypesProvider'

function GroupsToggleButtons({ onChanged }) {
  const { dataGroups, loading } = useContext(GroupsContext)

  return loading ? (
    <LinearProgress />
  ) : (
    <ToggleGroup
      name="indicators"
      buttons={dataGroups.map((e) => ({
        key: e.id,
        name: e.name,
      }))}
      onChanged={onChanged}
    />
  )
}

GroupsToggleButtons.propTypes = {
  onChanged: PropTypes.func,
}

function GroupsList({ view }) {
  const { dataGroups, loading } = useContext(GroupsContext)
  const groupCode =
    view === '' && dataGroups.length > 0 ? dataGroups[0].id : view
  return loading || groupCode === '' ? (
    <CircularProgress />
  ) : (
    <TypesProvider query={{ groupCode: groupCode }}>
      <ListChartsIndicators view={groupCode} />
    </TypesProvider>
  )
}

GroupsList.propTypes = {
  view: PropTypes.string.isRequired,
}

function Indicators() {
  const { isAuthenticated, getToken, login } = useAuth()
  const [view, setView] = useState('')

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  const handleGroupChange = (name, v) => {
    setView(v)
  }

  return (
    <SystemMenu>
      <h1>Показатели</h1>
      <GroupsProvider>
        <GroupsToggleButtons onChanged={handleGroupChange} />
        <GroupsList view={view} />
      </GroupsProvider>
    </SystemMenu>
  )
}

export default Indicators
