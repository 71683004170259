import React, { useEffect } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import { Box } from '@mui/material'
import SystemMenu from '../../shared/ui/SystemMenu'
import AppsProvider from '../../app/Sonars/AppsProvider'
import AppsTable from '../../widgets/DataView/AppsTable'

function Apps() {
  const { isAuthenticated, getToken, login } = useAuth()

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  return (
    <SystemMenu>
      <h1>Приложения</h1>
      <AppsProvider>
        <AppsTable />
        <Box height={20} />
      </AppsProvider>
    </SystemMenu>
  )
}
export default Apps
