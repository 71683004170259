import React, { useContext } from 'react'
import { Alert, Box, CircularProgress, Stack, Typography } from '@mui/material'
import SingleDigitalValue from './SingleDigitalValue'
import BuildsInfoContext from '../../app/System/BuildsInfoContext'
import BuildCard from '../../shared/ui/BuildCard'

function BuildsInfo() {
  const { data, loading, error } = useContext(BuildsInfoContext)

  if (error) return <Alert>{error}</Alert>

  if (loading) return <CircularProgress />

  return (
    <Stack
      sx={{ width: '100%', mt: 2 }}
      direction={'row'}
      alignItems={'start'}
      alignContent={'space-between'}
      spacing={5}
    >
      <Box>
        <Stack direction={'column'} alignItems={'stretch'}>
          <Typography variant={'caption'}>
            Последняя загруженная сборка
          </Typography>
          {!data.last ? (
            <Alert severity={'info'} label={'Нет информации'} />
          ) : (
            <BuildCard build={data.last} />
          )}
        </Stack>
      </Box>
      <SingleDigitalValue
        label={'Всего'}
        value={data.total ?? 0}
        round={false}
        units={['']}
      />
    </Stack>
  )
}

export default BuildsInfo
