import React, { useContext, useState } from 'react'
import { Alert, Box, CircularProgress, IconButton, Stack } from '@mui/material'
import DfContext from '../../app/System/DfContext'
import { formatFileSize } from '../../shared/units'
import { PieChart, Cell, Pie } from 'recharts'
import SingleDigitalValue from './SingleDigitalValue'
import { CleaningServices } from '@mui/icons-material'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import { useAuth } from '../../app/OAuth/Provider'
import api from '../../shared/api'

const RADIAN = Math.PI / 180

function DfInfo() {
  const { data, loading, error, refresh } = useContext(DfContext)

  const [showConfirm, setShowConfirm] = useState(false)

  if (error) return <Alert severity={'error'}>{error}</Alert>

  if (loading) return <CircularProgress />

  const pieData = [
    {
      name: 'Свободно',
      value: data.disk_total_space - data.disk_free_space,
      color: '#0056bf',
    },
    { name: 'Занято', value: data.disk_free_space, color: '#00b4d4' },
  ]

  const { getToken } = useAuth()

  const handlePrune = () => {
    setShowConfirm(false)
    getToken().then((accessToken) => {
      api
        .delete('/v1/admins/system/prune', {
          authorization: accessToken,
        })
        .then((res) => {
          refresh()
        })
    })
  }

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {formatFileSize(pieData[index].value)}
      </text>
    )
  }

  return (
    <>
      <ConfirmDialog
        show={showConfirm}
        message={'Удалить мусорные данные сборок?'}
        onHide={() => setShowConfirm(false)}
        accept={handlePrune}
      />
      <Stack
        sx={{ width: '100%' }}
        spacing={5}
        direction={'row'}
        alignItems={'center'}
        alignContent={'space-between'}
      >
        <Box>
          <PieChart width={300} height={150}>
            <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={pieData}
              cx="50%"
              cy="85%"
              outerRadius={100}
              fill="#8884d8"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </Box>
        <SingleDigitalValue
          label={'Свободно на диске'}
          value={data.disk_free_space ?? 0}
        />
        <SingleDigitalValue
          label={'Занято сборками'}
          value={data.builds_take_up ?? 0}
        />
        <SingleDigitalValue
          label={'Данные удаленных сборок'}
          value={data.lost_builds_data ?? 0}
        >
          {data.lost_builds_data > 0 ? (
            <IconButton sx={{ mx: 2 }} onClick={() => setShowConfirm(true)}>
              <CleaningServices />
            </IconButton>
          ) : null}
        </SingleDigitalValue>
      </Stack>
    </>
  )
}

export default DfInfo
