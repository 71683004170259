import React, { useContext, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import EditDialog from '../EditDialog/EditDialog'
import EditIcon from '@mui/icons-material/Edit'
import CreateDialog from '../CreateDialog/CreateDialogApps'
import DeleteIcon from '@mui/icons-material/Delete'
import AppsContext from '../../app/Sonars/AppsContext'
import api from '../../shared/api'
import { useAuth } from '../../app/OAuth/Provider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import SnackbarNotification from '../../shared/ui/Components/SnackbarNotification'

function AppsTable() {
  const { getToken } = useAuth()

  const { dataApps, setData, refreshApps, loading } = useContext(AppsContext)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const [currentRow, setCurrentRow] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isEditingId, setEditingId] = useState(null)
  const [deleted, setDeleted] = useState({})
  const [deletingId, setDeletingId] = useState(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarNotification, setSnackbarNotification] = useState({
    message: '',
    severity: 'success',
  })

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = dataApps.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)
    setDialogOpen(false)
    setEditingId(updatedRow.id)
    getToken().then((accessToken) => {
      api
        .put(`/v1/settings/apps/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          setSnackbarNotification({
            message: 'Успешное изменение данных',
            severity: 'success',
          })
          setSnackbarOpen(true)
        })
        .catch((error) => {
          setSnackbarNotification({
            message: 'Ошибка изменения данных',
            severity: 'error',
          })
          setSnackbarOpen(true)
          console.error('Ошибка изменения данных:', error)
        })
        .finally(() => {
          setEditingId(null)
        })
    })
  }

  const handleCreateApp = (appData) => {
    setIsCreating(true)
    setCreateDialogOpen(false)
    getToken().then((accessToken) => {
      api
        .post(`/v1/settings/apps/create`, appData, {
          authorization: accessToken,
        })
        .then(() => {
          setSnackbarNotification({
            message: 'Приложение успешно создано',
            severity: 'success',
          })
          setSnackbarOpen(true)
          refreshApps()
        })
        .catch((error) => {
          setSnackbarNotification({
            message: `Ошибка создания приложения`,
            severity: 'error',
          })
          setSnackbarOpen(true)
          console.error('Ошибка создания приложения:', error)
        })
        .finally(() => {
          setIsCreating(false)
        })
    })
  }

  const handleRemove = () => {
    setEditingId(deletingId)
    getToken().then((accessToken) => {
      api
        .delete(`/v1/settings/apps/remove/${deletingId}`, {
          authorization: accessToken,
        })
        .then((_) => {
          const newDeleted = {}
          for (const d in deleted) {
            newDeleted[d] = true
          }
          newDeleted[deletingId] = true
          setDeleted(newDeleted)
          setSnackbarNotification({
            message: 'Успешное удаление приложения',
            severity: 'success',
          })
          setSnackbarOpen(true)
        })
        .catch((e) => {
          console.log(e)
          setSnackbarNotification({
            message: 'Ошибка удаления приложения',
            severity: 'error',
          })
          setSnackbarOpen(true)
        })
        .finally(() => {
          setEditingId(null)
        })
    })
  }

  const handleConfirmRemove = (row) => {
    setDeletingId(row.id)
    setShowConfirm(true)
  }

  return (
    <>
      <ConfirmDialog
        show={showConfirm}
        message={'Удалить приложение?'}
        onHide={() => setShowConfirm(false)}
        accept={handleRemove}
      />
      {currentRow && (
        <EditDialog
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          title="Редактирование данных"
          fields={[
            { key: 'code', label: 'Код' },
            { key: 'ext', label: 'Расширение' },
            { key: 'template', label: 'Шаблон файла' },
            { key: 'versionTemplate', label: 'Шаблон версии' },
            { key: 'name', label: 'Описание' },
          ]}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <CreateDialog
        show={createDialogOpen}
        onHide={() => setCreateDialogOpen(false)}
        onSave={handleCreateApp}
        data={{ code: '', name: '', ext: '' }}
      />
      <SnackbarNotification
        notification={snackbarNotification}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          marginBottom: 2,
        }}
      >
        {isCreating || loading ? (
          <CircularProgress color={'success'} />
        ) : (
          <Button
            variant={'contained'}
            color={'success'}
            disableElevation={true}
            size="large"
            onClick={() => setCreateDialogOpen(true)}
          >
            Новое приложение
          </Button>
        )}
      </Box>
      <ContentPaper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="apps table">
            <TableHead>
              <TableRow>
                <TableCell>Код</TableCell>
                <TableCell>Расширение</TableCell>
                <TableCell>Шаблон файла</TableCell>
                <TableCell>Шаблон версии</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataApps.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                    }}
                  >
                    Список пуст
                  </TableCell>
                </TableRow>
              ) : null}
              {dataApps.map((row) =>
                deleted[row.id] ? (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      colSpan={5}
                      sx={{
                        textAlign: 'center',
                        color: '#999',
                        height: 70,
                        background:
                          'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.03) 50%, rgba(0,0,0,0))',
                      }}
                    >
                      Приложение удалено
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.ext}</TableCell>
                    <TableCell>{row.template}</TableCell>
                    <TableCell>{row.versionTemplate}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {isEditingId === row.id ? (
                        <CircularProgress size={24} />
                      ) : (
                        <>
                          <Tooltip title="Изменить">
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEditClick(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Удалить">
                            <IconButton
                              aria-label="delete"
                              color={'error'}
                              onClick={() => handleConfirmRemove(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentPaper>
    </>
  )
}

export default AppsTable
