import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  MenuItem,
} from '@mui/material'
import PropTypes from 'prop-types'

function EditDialog(props) {
  const handleClose = () => {
    props.onHide()
  }

  const handleSave = () => {
    handleClose()
    if (props.onSave != null) props.onSave(props.data)
  }

  const handleChange = (e, key) => {
    const newData = { ...props.data, [key]: e.target.value }
    props.setData(newData)
  }

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      aria-labelledby="edit-dialog-title"
    >
      {props.title && (
        <DialogTitle id="edit-dialog-title">{props.title}</DialogTitle>
      )}
      <DialogContent>
        <Grid container spacing={2}>
          {props.fields.map((field, index) => (
            <Grid item xs={12} key={index}>
              <TextField
                margin="dense"
                id={field.key}
                label={field.label}
                type={field.type || 'text'}
                fullWidth
                select={field.type === 'select'}
                variant="outlined"
                value={props.data[field.key] || ''}
                onChange={(e) => handleChange(e, field.key)}
              >
                {field.type === 'select'
                  ? field.values.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button
          onClick={handleSave}
          variant={'contained'}
          color={'primary'}
          disableElevation={true}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
}

export default EditDialog
