import React, { useState } from 'react'
import { Box } from '@mui/material'
import StatsProvider from '../../../app/Stats/StatsProvider'
import LinearChart from '../../../widgets/Chart/LinearChart'
import SegmentedChart from '../../../widgets/Chart/SegmentedChart'
import DiagramChart from '../../../widgets/Chart/DiagramChart'
import ToggleButtonIndicators from '../../../widgets/ToggleButton/ToggleButtonIndicators'
import ContentToggleButtonLinear from '../../../widgets/ToggleButton/ContentToggleButton/ContentToggleButtonLinear'
import ContentToggleButtonSegmented from '../../../widgets/ToggleButton/ContentToggleButton/ContentToggleButtonSegmented'
import ContentToggleButtonDiagram from '../../../widgets/ToggleButton/ContentToggleButton/ContentToggleButtonDiagram'
import PropTypes from 'prop-types'

const getEndDate = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return Math.floor(now.getTime() / 1000)
}

const getStartDate = (period) => {
  const now = new Date()
  switch (period) {
    case 'year':
      now.setFullYear(now.getFullYear() - 1)
      break
    case 'quarter':
      now.setMonth(now.getMonth() - 3)
      break
    case 'month':
      now.setMonth(now.getMonth() - 1)
      break
    case 'week':
      now.setDate(now.getDate() - 7)
      break
    default:
      break
  }
  now.setHours(0, 0, 0, 0)
  return Math.floor(now.getTime() / 1000)
}

const chartConfig = {
  segmented: {
    period: 'quarter',
    detail: 'week',
    chartType: 'bar',
    Component: SegmentedChart,
    buttonConfig: ContentToggleButtonSegmented,
  },
  linear: {
    period: 'quarter',
    detail: 'day',
    chartType: 'line',
    Component: LinearChart,
    buttonConfig: ContentToggleButtonLinear,
  },
  diagram: {
    period: 'quarter',
    detail: 'day',
    chartType: 'diagram',
    Component: DiagramChart,
    buttonConfig: ContentToggleButtonDiagram,
  },
}

function ChartWrapperIndicators({ code, group, value }) {
  const initialState = chartConfig[value] || {}
  const [state, setState] = useState(initialState)

  const handleToggleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const { Component, buttonConfig } = chartConfig[value] || {}
  return (
    <>
      <Box my={2}>
        {buttonConfig && (
          <ToggleButtonIndicators
            setState={handleToggleChange}
            startState={state}
            buttonsGroupConfig={buttonConfig}
          />
        )}
      </Box>
      <StatsProvider
        type="indicators"
        query={{
          startDate: getStartDate(state.period),
          endDate: getEndDate(),
          segment: state.detail,
          expectedPeriod: state.period,
          group,
          code,
        }}
      >
        {Component && (
          <Component
            segment={state.detail}
            chartType={state.chartType}
            startDate={getStartDate(state.period)}
            endDate={getEndDate()}
          />
        )}
      </StatsProvider>
    </>
  )
}

ChartWrapperIndicators.propTypes = {
  code: PropTypes.string,
  group: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default ChartWrapperIndicators
