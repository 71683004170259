import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import BuildsInfoContext from './BuildsInfoContext'
import api from '../../shared/api'
import { useAuth } from '../OAuth/Provider'

function BuildsInfoProvider({ children }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState({})

  const { getToken } = useAuth()

  useEffect(() => {
    setLoading(true)
    getToken().then((accessToken) =>
      api
        .get('/v1/admins/system/builds', {
          authorization: accessToken,
        })
        .then((r) => {
          setData(r)
          setLoading(false)
          setLoaded(true)
        })
        .catch((e) => {
          setError(e.message)
          setLoading(false)
        })
    )
  }, [])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      data,
    }),
    [loaded, loading, error, data]
  )

  return (
    <BuildsInfoContext.Provider value={contextValue}>
      {children}
    </BuildsInfoContext.Provider>
  )
}

BuildsInfoProvider.propTypes = {
  children: PropTypes.node,
}

export default BuildsInfoProvider
