import { Box } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const threadBgColorsMap = {
  test: '#ffcc00',
  release: '#aad400',
  dev: '#c90b0b',
  beta: '#0b9dc9',
}

const threadTextColorsMap = {
  dev: '#fff',
  beta: '#fff',
}

function ColoredThread({ thread }) {
  return (
    <Box
      component="span"
      sx={{
        bgcolor: threadBgColorsMap[thread] ?? '#eee',
        color: threadTextColorsMap[thread] ?? 'black',
        borderRadius: '10px',
        padding: '3px 8px',
        margin: '0 3px',
      }}
    >
      {thread}
    </Box>
  )
}

ColoredThread.propTypes = {
  thread: PropTypes.string,
}

function BuildVersion({ fullVersion, version, thread, build }) {
  const v =
    fullVersion != null ? fullVersion.split('-') : [version, thread, build]
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '3px',
      }}
    >
      {v[0]}
      <ColoredThread thread={v[1]} />
      {v[2]}
    </Box>
  )
}

BuildVersion.propTypes = {
  fullVersion: PropTypes.string,
  version: PropTypes.string,
  thread: PropTypes.string,
  build: PropTypes.number,
}

export { ColoredThread, BuildVersion as default }
