import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'

const EditDialogBuilds = ({ show, onHide, onSave, data, setData }) => {
  const [changes, setChanges] = useState(data.changes)

  useEffect(() => {
    setChanges(data.changes)
  }, [data])

  const handleChangesChange = (event) => {
    setChanges(event.target.value)
    setData({ ...data, changes: event.target.value })
  }

  const handleSaveClick = () => {
    onSave({ ...data, changes })
  }

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Изменения</DialogTitle>
      <DialogContent sx={{ width: '25rem' }}>
        <TextField
          autoFocus
          margin="dense"
          id="changes"
          label="Изменения"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={changes}
          onChange={handleChangesChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide}>Отмена</Button>
        <Button
          onClick={handleSaveClick}
          color="primary"
          variant={'contained'}
          disableElevation={true}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditDialogBuilds.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
}

export default EditDialogBuilds
