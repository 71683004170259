import React, { useContext, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import EditDialog from '../EditDialog/EditDialog'
import EditIcon from '@mui/icons-material/Edit'
import GroupsContext from '../../app/Administration/GroupsContext'
import api from '../../shared/api'
import { useAuth } from '../../app/OAuth/Provider'
import SnackbarNotification from '../../shared/ui/Components/SnackbarNotification'

function OptionsGroupsTable() {
  const { getToken } = useAuth()

  const { dataGroups, setData } = useContext(GroupsContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [isEditingId, setEditingId] = useState(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarNotification, setSnackbarNotification] = useState({
    message: '',
    severity: 'success',
  })

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = dataGroups.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setEditingId(updatedRow.id)
    setData(updatedData)
    getToken().then((accessToken) => {
      api
        .put(`/v1/admins/groups/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          setSnackbarNotification({
            message: 'Данные успешно изменены',
            severity: 'success',
          })
          setSnackbarOpen(true)
          setEditingId(null)
        })
        .catch((error) => {
          setSnackbarNotification({
            message: 'Ошибка изменения данных',
            severity: 'error',
          })
          setSnackbarOpen(true)
          console.error('Ошибка изменения данных:', error)
        })
    })
    setDialogOpen(false)
  }

  return (
    <>
      {currentRow && (
        <EditDialog
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          title="Редактирование данных"
          fields={[{ key: 'name', label: 'Название' }]}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <SnackbarNotification
        notification={snackbarNotification}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Код</TableCell>
              <TableCell>Название</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataGroups.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    textAlign: 'center',
                    color: '#999',
                    height: 70,
                  }}
                >
                  Список пуст
                </TableCell>
              </TableRow>
            ) : null}

            {dataGroups.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell sx={{ width: '15%' }}>{row.id}</TableCell>
                <TableCell sx={{ width: '15%' }}>{row.name}</TableCell>
                <TableCell align="right">
                  {isEditingId === row.id ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Tooltip title="Изменить">
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditClick(row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default OptionsGroupsTable
