function formatUnit(value, units = ['штука', 'штуки', 'штук']) {
  const v = parseInt(String(value).substr(-1))
  let u = 2
  if (v === 1) u = 0
  if (v === 2 || v === 3 || v === 4) u = 1
  if (value > 10 && value < 20) u = 2
  return value + ' ' + units[u]
}

const formatFileSize = (bytes) =>
  formatDigital(bytes, 1024, ['б', 'кБ', 'МБ', 'ГБ'])

const formatDigital = (value, thousand, units) => {
  if (value === 0) return '0 ' + units[0]

  const i = Math.floor(Math.log(value) / Math.log(thousand))
  let size = value / Math.pow(thousand, i)
  if (i > 0) size = size.toFixed(size < 10 ? 2 : size < 100 ? 1 : 0)

  return `${size} ${units[i]}`
}

export { formatUnit, formatFileSize, formatDigital }
