import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import UserContext from './UserContext'
import { useAuth } from '../OAuth/Provider'
import api from '../../shared/api'

function UserProvider({ children }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [user, setUser] = useState({
    id: '123',
    role: 'user',
    name: 'name',
  })

  const { getToken } = useAuth()

  useEffect(() => {
    getToken().then((accessToken) =>
      api
        .get('/v1/auth/user', { authorization: accessToken })
        .then((r) => {
          setUser(r)
          setLoading(false)
          setLoaded(true)
        })
        .catch((e) => {
          setError(e.message)
        })
    )
  }, [])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      user,
    }),
    [loaded, loading, error, user]
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.object,
}

export default UserProvider
