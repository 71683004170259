import React, { useContext, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Stack,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import EditIcon from '@mui/icons-material/Edit'
import TokenAccessContext from '../../app/Administration/TokenAccessContext'
import EditDialogTokenAccess from '../EditDialog/EditDialogTokenAccess'
import api from '../../shared/api'
import { useAuth } from '../../app/OAuth/Provider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import SnackbarNotification from '../../shared/ui/Components/SnackbarNotification'
import ColoredThread from '../../shared/ui/BuildVersion'

function OptionsTokenAccessTable() {
  const { getToken } = useAuth()

  const { data, loading, setData, refreshTokens } =
    useContext(TokenAccessContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isEditingId, setEditingId] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarNotification, setSnackbarNotification] = useState({
    message: '',
    severity: 'success',
  })

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const createToken = () => {
    setIsCreating(true)
    getToken().then((accessToken) => {
      api
        .post(`/v1/admins/tokens/create`, null, {
          authorization: accessToken,
        })
        .then(() => {
          setSnackbarNotification({
            message: 'Токен успешно создан',
            severity: 'success',
          })
          setSnackbarOpen(true)
          refreshTokens()
        })
        .catch((error) => {
          console.error('Ошибка создания токена:', error)
          setSnackbarNotification({
            message: `Ошибка создания токена`,
            severity: 'error',
          })
          setSnackbarOpen(true)
        })
        .finally(() => {
          setIsCreating(false)
        })
    })
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
  }

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)
    setEditingId(updatedRow.id)
    getToken().then((accessToken) => {
      api
        .put(`/v1/admins/tokens/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          setSnackbarNotification({
            message: 'Успешное изменение данных',
            severity: 'success',
          })
          setSnackbarOpen(true)
        })
        .catch((error) => {
          setSnackbarNotification({
            message: 'Ошибка изменения данных',
            severity: 'error',
          })
          setSnackbarOpen(true)
          console.error('Ошибка изменения данных:', error)
        })
        .finally(() => {
          setEditingId(null)
        })
    })
    setDialogOpen(false)
  }

  return (
    <Stack mt={2}>
      {currentRow && (
        <EditDialogTokenAccess
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <SnackbarNotification
        notification={snackbarNotification}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        {isCreating || loading ? (
          <CircularProgress color={'success'} />
        ) : (
          <Button
            color={'success'}
            disableElevation={true}
            variant={'contained'}
            size="large"
            onClick={createToken}
          >
            Создать новый токен
          </Button>
        )}
      </Stack>
      <ContentPaper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Ветка</TableCell>
                <TableCell>Создан</TableCell>
                <TableCell>Истекает</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                    }}
                  >
                    Список пуст
                  </TableCell>
                </TableRow>
              ) : null}

              {data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell sx={{ color: '#8f8f8f' }}>
                    {row.id}
                    <IconButton
                      sx={{
                        marginLeft: '1.5rem',
                        marginTop: '-0.3rem',
                      }}
                      onClick={() => handleCopy(row.id)}
                    >
                      <CopyAllIcon aria-label="copy" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <ColoredThread thread={row.thread} />
                  </TableCell>
                  <TableCell>{row.create}</TableCell>
                  <TableCell>{row.expirationDate}</TableCell>
                  <TableCell align="right">
                    {isEditingId === row.id ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Tooltip title="Изменить">
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditClick(row)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentPaper>
    </Stack>
  )
}

export default OptionsTokenAccessTable
