import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from './Provider/useAuth'
import System from '../../shared/ui/System'
import { Alert } from '@mui/material'

function OAuth() {
  const { error, loading } = useAuth()

  return (
    <System>
      <h1>Авторизация</h1>
      <Alert message={error} />
      {loading ? <p>Загрузка...</p> : null}
      <p>
        <Link to="/">На главную</Link>
      </p>
    </System>
  )
}

export default OAuth
