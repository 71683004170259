import { Box } from '@mui/material'
import PropTypes from 'prop-types'

function FormattedDate({ date, format }) {
  const a = (date ?? '').split(/\D+/)
  let formatted = ''
  if (a.length < 3) {
    formatted = date
  } else {
    const d = new Date(
      Date.UTC(a[0], a[1], a[2], a[3] ?? 0, a[4] ?? 0, a[5] ?? 0)
    )
    formatted = d.toLocaleDateString(
      'ru',
      format ?? {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
    )
  }
  return <Box sx={{ color: '#555' }}>{formatted}</Box>
}

FormattedDate.propTypes = {
  date: PropTypes.string,
  format: PropTypes.object,
}

export default FormattedDate
