import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import ToggleGroup from '../../shared/ui/Components/ToggleGroup'

const ToggleButtonIndicators = ({
  setState,
  startState,
  buttonsGroupConfig,
}) => {
  const handleChange = (name, value) => {
    setState(name, value)
  }

  return (
    <Box display="flex">
      {buttonsGroupConfig.map((group, index) => {
        const disabledButtons =
          group.name === 'detail' && group.getDisabledDetailButtons
            ? group.getDisabledDetailButtons(startState.period)
            : []

        return (
          <ToggleGroup
            key={index}
            title={group.title}
            name={group.name}
            color={'standard'}
            sx={group.sx}
            onChanged={handleChange}
            buttons={group.buttons}
            startValue={startState[group.name]}
            disabledButtons={disabledButtons}
          />
        )
      })}
    </Box>
  )
}

ToggleButtonIndicators.propTypes = {
  setState: PropTypes.func.isRequired,
  startState: PropTypes.shape({
    period: PropTypes.string,
    detail: PropTypes.string,
    chartType: PropTypes.string,
  }),
  buttonsGroupConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string.isRequired,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          name: PropTypes.string,
          icon: PropTypes.element,
        })
      ),
      sx: PropTypes.object,
      getDisabledDetailButtons: PropTypes.func,
    })
  ),
}

export default ToggleButtonIndicators
