import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material'
import PropTypes from 'prop-types'

const convertToInputFormat = (dateString) => {
  const [day, month, year] = dateString.split('-')
  return `${year}-${month}-${day}`
}

const convertToDisplayFormat = (dateString) => {
  const [year, month, day] = dateString.split('-')
  return `${day}-${month}-${year}`
}

const EditDialogBuilds = ({ show, onHide, onSave, data, setData }) => {
  const [formData, setFormData] = useState(data)
  const [expirationDate, setExpirationDate] = useState(
    convertToInputFormat(data.expirationDate)
  )

  useEffect(() => {
    setFormData(data)
    setExpirationDate(convertToInputFormat(data.expirationDate))
  }, [data])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleDateChange = (event) => {
    const newValue = event.target.value
    setExpirationDate(newValue)
    setFormData((prevFormData) => ({
      ...prevFormData,
      expirationDate: convertToDisplayFormat(newValue),
    }))
  }

  const handleSaveClick = () => {
    setData(formData)
    onSave(formData)
  }

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Изменение токена доступа</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <InputLabel id="thread-select-label">Ветка</InputLabel>
          <Select
            labelId="thread-select-label"
            id="thread-select"
            label="Ветка"
            value={formData.thread || ''}
            onChange={handleInputChange}
            name="thread"
            sx={{
              borderRadius: '5px',
            }}
          >
            {['test', 'dev', 'beta'].map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Дата истечения"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={expirationDate}
          onChange={handleDateChange}
          fullWidth
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide}>Отмена</Button>
        <Button
          onClick={handleSaveClick}
          color="primary"
          variant={'contained'}
          disableElevation={true}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditDialogBuilds.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    thread: PropTypes.string,
    expirationDate: PropTypes.string,
  }).isRequired,
  setData: PropTypes.func,
}

export default EditDialogBuilds
