import { createContext } from 'react'

const TokenAccessContext = createContext({
  loading: false,
  loaded: false,
  data: [],
  error: null,
  refreshTokens: () => {},
})

export default TokenAccessContext
