import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import StatsContext from '../../app/Stats/StatsContext'
// import PropTypes from 'prop-types'
import CustomTooltip from '../../shared/ui/Components/CustomTooltip'
import LoadingAndAlertChart from '../../shared/ui/Components/LoadingAndAlertChart.jsx'

const colorForRange = ['#ffcc00', '#aad400', '#c90b0b']

function DiagramChart() {
  const { data, loading, error } = useContext(StatsContext)

  const aggregatedData = data.map((rangeItem, index) => ({
    label: rangeItem.range.label,
    value: rangeItem.data.reduce((acc, entry) => acc + entry.count, 0),
    color: colorForRange[index % colorForRange.length],
  }))

  return (
    <Box sx={{ position: 'relative', minHeight: '300px' }}>
      <LoadingAndAlertChart error={error} loading={loading} />

      {!loading && aggregatedData.length > 0 && (
        <PieChart width={300} height={300}>
          <Pie
            data={aggregatedData}
            dataKey="value"
            nameKey="label"
            outerRadius={100}
            fill="#8884d8"
          >
            {aggregatedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      )}
    </Box>
  )
}

export default DiagramChart
