import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'

const ResetPasswordDialog = ({ show, onHide, onSave, data: initialData }) => {
  const [data, setData] = useState({ ...initialData, newPassword: '' })

  useEffect(() => {
    setData({ ...initialData, newPassword: '' })
  }, [initialData])

  const handleCreatePassword = (e) => {
    const { name, value } = e.target
    setData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSavePassword = () => {
    if (onSave) onSave({ id: data.id, newPassword: data.newPassword })
  }

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Сбросить пароль</DialogTitle>
      <DialogContent>
        <TextField
          label="Новый пароль"
          type="password"
          value={data.newPassword || ''}
          onChange={handleCreatePassword}
          name="newPassword"
          fullWidth
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSavePassword} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ResetPasswordDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    newPassword: PropTypes.string,
  }).isRequired,
}

export default ResetPasswordDialog
