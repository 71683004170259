import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e38900', light: '#ffcc00', dark: '#e38900' },
    secondary: {
      main: '#e38900',
      light: '#e08f00',
      dark: '#8c3100',
      contrastText: '#fff',
    },
    success: {
      main: '#38ab57',
      light: '#34d45f',
      dark: '#2f8a70',
      contrastText: '#fff',
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '0.8rem',
          border: 'none',
          backgroundColor: 'white',
        },
        select: {
          paddingLeft: '20px',
        },
      },
    },
    ListItemText: {
      styleOverrides: {
        root: {
          color: 'red',
          fontWeight: '900',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '0 20px 10px',
          borderRadius: '20px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-around',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        },
      },
    },
  },
})
